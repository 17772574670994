import { environment } from './../../environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { Connection, Proxy, hubConnection } from 'signalr-no-jquery';
import { Post } from '../models/post.model';

@Injectable({
    providedIn: 'root'
})
export class PostHubService {
    public connectionExists: Boolean;
    private connection: Connection;
    private proxy: Proxy;

    constructor() {
        console.log('PostHub Started...');
        this.connectionExists = false;
        this.connection = hubConnection(environment.signalRUrl, { useDefaultPath: false, logging: true });
        this.proxy = this.connection.createHubProxy('hubPost');
        this.registerOnServerEvents();

    }
    public start(officeId: string) {
        this.startConnection(officeId);
    }

    private async startConnection(officeId: string): Promise<void> {
        if (this.connectionExists) {
            this.connection.stop();
        }
        try {
            await this.connection.start({ transport: 'serverSentEvents' });
            await this.proxy.invoke("subscribePostHub", officeId);
            this.connectionExists = true;
        }
        catch (error) {
            console.log('*** Could not connect ' + error);
        }
    }

    private registerOnServerEvents(): void {
        this.proxy.off('Check', this.check);
        this.proxy.on('Check', this.check);

        this.proxy.off('PostUpdateEvent', (post) => this.onUpdateEvent(post));
        this.proxy.on('PostUpdateEvent', (post) => this.onUpdateEvent(post));
    }

    public postUpdateEvent = new EventEmitter<Post>();
    private onUpdateEvent = (postJson: any) => {
        console.log('1. onUpdateEvent');
        this.postUpdateEvent.emit(JSON.parse(postJson))
    };

    private check = (res) => {
        console.group('Check');
        console.log(res);
        console.groupEnd();
    };
}
