import { PostInfo } from './../models/post.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isWaybill'
})
export class IsWaybillPipe implements PipeTransform {

  transform(post: PostInfo): boolean {
    return post?.attachments?.find(a => a.isWaybill) ? true : false;
  }

}
