import { Office } from './../../models/office.model';
import { Stat } from './../../models/stat.model';
import { PostInfo } from './../../models/post.model';
import { Component, OnInit } from '@angular/core';
import { EpochtaApiService } from 'src/app/services/epochta-api.service';

@Component({
    selector: 'app-archive',
    templateUrl: './archive.component.html',
    styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {

    constructor(private apiService: EpochtaApiService) { }

    posts: PostInfo[];
    stat: Stat;
    office: Office;
    codes: string;

    async ngOnInit() {
        this.posts = await this.apiService.posts.getOfficeArchive()
    }

    isQuintation(status: string) {
        return status == 'completed' ? true : false;
    }

    genPdf(postId: string) {
        window.open(this.apiService.pdf.postReceiveFormPdf(postId));
    }
}
