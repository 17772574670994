import { EpochtaApiService } from './../../services/epochta-api.service';
import { PostAttachmentSave, PostInfo } from 'src/app/models/post.model';
import {Component, Inject, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Attachment, AttachmentReq } from 'src/app/models/attachment.model';
import {Office} from "../../models/office.model";
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'app-departure-details',
    templateUrl: './departure-details.component.html',
    styleUrls: ['./departure-details.component.scss']
})
export class DepartureDetailsComponent implements OnInit {
    post: PostInfo;
    statuses = ["new", "waitingPayment", "waitingOfficeActions", "waitingForCourier", "waitingDelivery", "completed"];
    isLoading: boolean = false;
    waybill: Attachment;
    office: Office;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private route: ActivatedRoute,
        private apiService: EpochtaApiService,
        private router: Router,
        private toastr: ToastrService
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe(async params => {
            if (!params["id"]) this.router.navigateByUrl("/history");
            this.getPost(params["id"]);
            this.office = await this.apiService.office.getCurrentOffice();
        });
    }

    async getPost(id: string) {
        this.post = await this.apiService.posts.getPostDetails(id);

        if (this.post && this.post.attachments && this.post.attachments.length) {
            this.waybill = this.post.attachments.find(m => m.isWaybill);
        }
    }

    activeStatus(status: string) {
        return status == this.post.status ? "active" : "";
    }

    downloadAttachment(postId: string, id: string) {
        this.apiService.posts.downloadAttachment(postId, id)
    }

    async changePostStatus(status: string) {
        const res = await this.apiService.posts.changePostStatus(this.post.id, status);
        console.log(res);
        this.post = res;
        if (this.post.cdekNumber) return;
        setTimeout(() => {
            this.getPostInfo(this.post);
        }, 1000);
    }
    async removePost(id: string) {
        await this.apiService.posts.removePost(id);
        this.toastr.success("Успешно удалено");
        setTimeout(() => this.router.navigateByUrl("/history"), 1000);
    }

    async getPostInfo(post: PostInfo) {
        this.isLoading = true;
        const res = await this.apiService.posts.getPostDetailsCdek(post.id);

        if (res)
            this.post = res;

        this.isLoading = false;
    }

    isReceivePdf(status: string) {
        return status == "completed" ? true : false;
    }

    isSendPdf(status: string) {
        return status != "new" && status != "waitingPayment" ? true : false;
    }

    isIndexPdf(status: string) {
        return status != "new" && status != "waitingPayment" ? true : false;
    }

    isNew(status: string) {
        return status == "new" || status == "waitingPayment" ? true : false;
    }

    genPaymentPdf(postId) {
        window.open(this.apiService.pdf.postPaymentFormPdf(postId));
    }

    genReceivePdf(postId) {
        window.open(this.apiService.pdf.postReceiveFormPdf(postId));
    }

    genSendPdf(postId) {
        window.open(this.apiService.pdf.postSendFormPdf(postId));
    }

    genIndexPdf(postId) {
        window.open(this.apiService.pdf.postIndexFormPdf(postId));
    }

    genCoverPrint(postId, width, height) {
        window.open(this.apiService.pdf.postCoverPrint(postId, width, height), '_blank');
    }

    async print(postInfo: PostInfo) {
        this.apiService.posts.printPosts([postInfo.id]).subscribe(res => {
            var file = new Blob([res], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        });
        await this.apiService.posts.setPrinted(postInfo.id);
        postInfo.isPrinted = true;
    }


    async handleFileInput(files: FileList) {
        if (!files) return;
        const file = files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
            const base64 = reader.result.toString();
            const attachment: Attachment = {
                fileBase64: base64.substring(base64.indexOf(",") + 1, base64.length),
                filename: file.name,
                extension: file.type.substring(file.type.indexOf("/") + 1, file.type.length),
                size: file.size,
                isWaybill: true
            };

            this.post.attachments = this.post.attachments.filter(a => !a.isWaybill);
            this.post.attachments.push(attachment);

            const post: PostAttachmentSave = {
                id: this.post.id,
                attachments: this.post.attachments
            }

            this.apiService.posts.saveAttachments(post).then(res => {
                this.post.attachments = res.attachments;
                console.log(this.post.attachments);
                if (res && res.attachments && res.attachments.length) {
                    this.waybill = res.attachments.find(m => m.isWaybill);
                }
            });
        };
    }

    public editFileName(attr: any, i: any, post: PostInfo): any {
        if (attr.alternativeName === null || attr.alternativeName === '') {
            attr.alternativeName = attr.filename;
        }
        attr.isModeEdit = !attr.isModeEdit;
        setTimeout(() => {
            this.document.getElementById('rename-file-input-' + i).focus();
            this.document.getElementById('rename-file-input-' + i).onblur = () => {
                this.apiService.posts.updateAttachmentName(post);
                this.editFileName(attr, i, post);
            };
        }, 100);
    }

}
