import { Stat } from './../models/stat.model';
import { Office } from './../models/office.model';
import { Router } from '@angular/router';
import { LoginReq } from './../models/login.model';
import { Post, PostAttachmentSave, PostInfo } from './../models/post.model';
import { CustomerLocation } from './../models/customer-location.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Customer } from '../models/customer.model'
import { ToastrService } from 'ngx-toastr';


@Injectable({
    providedIn: 'root'
})
export class EpochtaApiService {
    apiUrl: string;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private router: Router
    ) { this.apiUrl = environment.apiUrl; }

    public readonly csv = ((api: this) => ({
        genCsvFile(officeId: string): any {
            return api.http.post(api.apiUrl + 'GenCsv', { Id: officeId }, { headers: api.makeOptions(), responseType: 'blob' });
        }
    }))(this);

    public readonly customers = ((api: this) => ({
        getCurrentCustomerProfile() {
            return api.apiCallGet("GetCurrentCustomerProfile");
        },
        getCustomers() {
            return api.apiCallGet("customers");
        },
        getCustomerById(id: string) {
            return api.apiCallGet(`customer/id/${id}`)
        },
        getCustomerByPhone(id: string) {
            return api.apiCallGet(`customer/phone/${id}`)
        },
        saveCustomer(customer: Customer) {
            return api.apiCall(`customer`, customer);
        },
        saveCustomerLocation(customerId: string, customerLocation: CustomerLocation) {
            return api.apiCall(`customer/id/${customerId}/location/update`, customerLocation);
        },
        removeCustomerLocation(customerId: string, locationIndex: number) {
            return api.apiCallGet(`customer/id/${customerId}/location/remove/id/${locationIndex}`);
        }
    }))(this);

    public readonly posts = ((api: this) => ({
        createPost(post: Post) {
            return api.apiCall("CreatePost", post);
        },
        getOfficeInbox() {
            return api.apiCallGet(`GetOfficeInbox`)
        },
        getOfficeAllPosts() {
            return api.apiCallGet(`GetOfficeAllPosts`)
        },
        getOfficeArchive() {
            return api.apiCallGet(`GetOfficeArchive`)
        },
        getPostDetails(id: string) {
            return api.apiCallGet(`GetPostDetails`, { id: id })
        },
        getPostDetailsCdek(id: string) {
            return api.apiCallGet(`UpdatePostDetailsFromCdekAndReturn`, { id: id })
        },
        changePostStatus(postId: string, status: string) {
            return api.apiCall(`ChangePostStatus?postId=${postId}&status=${status}&isOffice=true`, { postId: postId, status: status })
        },
        setPrinted(postId: string) {
            return api.apiCallGet(`SetPrinted?postId=${postId}`)
        },
        downloadAttachment(postId: string, attachmentId: string) {
            alert(api.apiUrl + `DownloadAttachmentFile?postId=${postId}&attachmentId=${attachmentId}`)
            window.open(api.apiUrl + `DownloadAttachmentFile?postId=${postId}&attachmentId=${attachmentId}`);
        },
        removePost(id: string) {
            return api.apiCallGet(`RemovePost`, { postId: id })
        },
        getLink(postId: string, attachmentId: string) {
            return api.apiUrl + `DownloadAttachmentFile?postId=${postId}&attachmentId=${attachmentId}`;
        },
        getOpenLink(postId: string, attachmentId: string) {
            return api.apiUrl + `OpenAttachmentFile?postId=${postId}&attachmentId=${attachmentId}`;
        },
        printPosts(ids: string[]) {
            return api.http.post(api.apiUrl + "OpenAllAttachmentFile", ids, { headers: api.makeOptions(), responseType: "blob" })
        },
        getStat() {
            return api.apiCallGet(`GetStat`);
        },
        saveAttachments(post: PostAttachmentSave) {
            return api.apiCall(`SavePostAttachments`, post);
        },
        updateAttachmentName(post: PostInfo) {
            return api.apiCall(`UpdateAttachmentName`, post);
        },
    }))(this);

    public readonly office = ((api: this) => ({
        login(login: LoginReq) {
            return api.apiCall("LoginOffice", login);
        },
        getCurrentOffice() {
            const id = localStorage.getItem("auth");
            if (!id) return;
            return api.apiCallGet(`GetOfficeById?id=${id}`);
        }
    }))(this);

    public readonly pdf = ((api: this) => ({
        /* Квитанция о вручении (Статус: completed) */
        postReceiveFormPdf(postId: string, openInBrowser: boolean = true) {
            // return api.apiCallGet("PostReceiveFormPdf", {postId: postId, openInBrowser: openInBrowser});
            return api.apiUrl + `PostReceiveFormPdf?postId=${postId}&openInBrowser=${openInBrowser}`;
        },
        /* Квитанция об отправлении (Статус: waitingOfficeActions, waitingDelivery, completed) */
        postSendFormPdf(postId: string, openInBrowser: boolean = true) {
            return api.apiUrl + `PostSendFormPdf?postId=${postId}&openInBrowser=${openInBrowser}`;
        },
        /* Опись отправления (Статус: waitingOfficeActions, waitingDelivery, completed) */
        postIndexFormPdf(postId: string, openInBrowser: boolean = true) {
            return api.apiUrl + `PostIndexFormPdf?postId=${postId}&openInBrowser=${openInBrowser}`;
        },
        /* Квитанция об оплате (Статус: waitingOfficeActions, waitingDelivery, completed) */
        postPaymentFormPdf(postId: string, openInBrowser: boolean = true) {
            return api.apiUrl + `PostPaymentFormPdf?postId=${postId}&openInBrowser=${openInBrowser}`;
        },
        /* Конверт */
        postCoverPrint(postId: string, width: number, height: number) {
            return api.apiUrl + `PostCoverPrint?postId=${postId}&width=${width}&height=${height}`;
        },
    }))(this);


    public startCustomerLogin(phone: string) {
        return this.apiCallGet("StartCustomerLogin", { 'phone': phone });
    }

    public continueCustomerLogin(code: string) {
        return this.apiCallGet("ContinueCustomerLogin", { 'code': code });
    }

    private apiCall(url: string, params: any): Promise<any> {
        return this.http
            .post(this.apiUrl + url, params, { headers: this.makeOptions() })
            .toPromise()
            .catch((e) => {
                if (e?.error?.message)
                    this.toastr.error(e?.error?.message)
                console.error(e);
                if (e.status == '401') this.router.navigateByUrl('/login');
            });
    }

    private apiCallGet(url: string, params?: any): Promise<any> {
        let options = { headers: this.makeOptions() } as any;

        if (params) {
            options.params = params;
        }

        return this.http
            .get(this.apiUrl + url, options)
            .toPromise()
            .catch((e) => {
                if (e?.error?.message)
                    this.toastr.error(e?.error?.message)
                console.warn(e);
                if (e.status == '401' || e.status == '0')
                    this.router.navigateByUrl('/login');
            });
    }

    makeOptions() {
        let headers: HttpHeaders = new HttpHeaders().set(
            'Content-Type',
            'application/json'
        );
        const token = localStorage.getItem('auth');

        if (token)
            headers = headers.set('auth', token);
        return headers;
    }
}
