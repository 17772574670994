import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PostInfo } from 'src/app/models/post.model';
import { EpochtaApiService } from 'src/app/services/epochta-api.service';

@Component({
    selector: 'app-shipment-archive',
    templateUrl: './shipment-archive.component.html',
    styleUrls: ['./shipment-archive.component.scss']
})
export class ShipmentArchiveComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private apiService: EpochtaApiService,
        private router: Router
    ) { }

    post: PostInfo;

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (!params["id"]) this.router.navigateByUrl("/history");
            this.getPost(params["id"]);
        });
    }

    async getPost(id: string) {
        this.post = await this.apiService.posts.getPostDetails(id);
    }

}
