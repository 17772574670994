import { Pipe, PipeTransform } from '@angular/core';
import { PostInfo } from '../models/post.model';

@Pipe({
    name: 'isPdf'
})
export class IsPdfPipe implements PipeTransform {

    transform(posts: PostInfo[]): number {
        return posts?.filter(m => m.attachments?.filter(a => !a.isWaybill).length == m.attachments?.length
                &&  m.status != 'new' && m.status != 'waitingPayment').length;
    }

}
