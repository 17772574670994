import { PostInfo } from './../models/post.model';
import { Pipe, PipeTransform } from '@angular/core';
import { Post } from '../models/post.model';

@Pipe({
    name: 'isPrinter'
})
export class IsPrinterPipe implements PipeTransform {

    transform(posts: PostInfo[], isNo?: boolean): number {
        return isNo === false ?
            posts?.filter(m => !m.isPrinted && m.status != 'new' && m.status != 'waitingPayment').length :
            posts?.filter(m => m.isPrinted && m.status != 'new' && m.status != 'waitingPayment').length
    }

}
