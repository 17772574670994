import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Office} from './models/office.model';
import {EpochtaApiService} from './services/epochta-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'CDEK: Доставка деловой корреспонденции';
    office: Office;
    public constructor(
        private apiService: EpochtaApiService,
        private titleService: Title
    ) { }

    async ngOnInit(): Promise<any> {
        this.office = await this.apiService.office.getCurrentOffice();
        this.titleService.setTitle('CDEK: Доставка деловой корреспонденции');
    }
}
