<app-header [title]="'Популярные вопросы'"></app-header>
<div class="container content">
    <div class="accordion" id="accordionExample">
        <div class="card">
            <div class="card-header" id="heading-1">
                <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
                    Как работает доставка электронных писем? 
                </button>
                </h2>
            </div>
      
            <div id="collapse-1" class="collapse show" aria-labelledby="heading-1" data-parent="#accordionExample">
                <div class="card-body">
                    В личном кабинете можно отправить письмо физическому лицу или организации. В качестве вложениия допустимы удовлетворяющие следующим критериям: 
                    После оплаты отправки заказного письма ему будет присвоен код отслеживания (трек-номер). По этому коду отслеживания можно письмо будет узнать текущий статус доставки письма. Писмо будет автоматически распечатно (цветные документы будут распечатаны в черно-белом варианте), вложено для подтверждения факта отправки письма можно распечатать "Отчет о доставке"
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" id="headingTwo">
                <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Какие письма можно получить в электронном виде?
                </button>
                </h2>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div class="card-body">
                    В личном кабинете можно отправить письмо физическому лицу или организации. В качестве вложениия допустимы удовлетворяющие следующим критериям: 
                    После оплаты отправки заказного письма ему будет присвоен код отслеживания (трек-номер). По этому коду отслеживания можно письмо будет узнать текущий статус доставки письма. Писмо будет автоматически распечатно (цветные документы будут распечатаны в черно-белом варианте), вложено для подтверждения факта отправки письма можно распечатать "Отчет о доставке"
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Какова юридическая сила электронных писем?
                </button>
                </h2>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                <div class="card-body">
                    В личном кабинете можно отправить письмо физическому лицу или организации. В качестве вложениия допустимы удовлетворяющие следующим критериям: 
                    После оплаты отправки заказного письма ему будет присвоен код отслеживания (трек-номер). По этому коду отслеживания можно письмо будет узнать текущий статус доставки письма. Писмо будет автоматически распечатно (цветные документы будут распечатаны в черно-белом варианте), вложено для подтверждения факта отправки письма можно распечатать "Отчет о доставке"
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" id="heading-2">
                <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse-2" aria-expanded="true" aria-controls="collapse-2">
                    Когда письмо считается врученным? 
                </button>
                </h2>
            </div>
      
            <div id="collapse-2" class="collapse" aria-labelledby="heading-2" data-parent="#accordionExample">
                <div class="card-body">
                    В личном кабинете можно отправить письмо физическому лицу или организации. В качестве вложениия допустимы удовлетворяющие следующим критериям: 
                    После оплаты отправки заказного письма ему будет присвоен код отслеживания (трек-номер). По этому коду отслеживания можно письмо будет узнать текущий статус доставки письма. Писмо будет автоматически распечатно (цветные документы будут распечатаны в черно-белом варианте), вложено для подтверждения факта отправки письма можно распечатать "Отчет о доставке"
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" id="heading-3">
                <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse-3" aria-expanded="true" aria-controls="collapse-3">
                    Сколько стоит услуга? 
                </button>
                </h2>
            </div>
      
            <div id="collapse-3" class="collapse" aria-labelledby="heading-3" data-parent="#accordionExample">
                <div class="card-body">
                    В личном кабинете можно отправить письмо физическому лицу или организации. В качестве вложениия допустимы удовлетворяющие следующим критериям: 
                    После оплаты отправки заказного письма ему будет присвоен код отслеживания (трек-номер). По этому коду отслеживания можно письмо будет узнать текущий статус доставки письма. Писмо будет автоматически распечатно (цветные документы будут распечатаны в черно-белом варианте), вложено для подтверждения факта отправки письма можно распечатать "Отчет о доставке"
                </div>
            </div>
        </div>
    </div>
</div>
