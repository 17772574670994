import {PostHubService} from './../../services/post-hub.service';
import {Stat} from './../../models/stat.model';
import {Office} from './../../models/office.model';
import {PostInfo} from './../../models/post.model';
import {EpochtaApiService} from 'src/app/services/epochta-api.service';
import {Component, OnInit} from '@angular/core';
import {CsvService} from "../../services/csv.service";
import {PostalServiceAlias} from "../../models/postal-service.model";
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'app-outgoing-shipment-history',
    templateUrl: './outgoing-shipment-history.component.html',
    styleUrls: ['./outgoing-shipment-history.component.scss']
})
export class OutgoingShipmentHistoryComponent implements OnInit {
    posts: PostInfo[];
    allPosts: PostInfo[];
    office: Office;
    codes: string;
    allCodes: string;
    stat: Stat;
    countShowPostCodes: number = 3;
    isAllPrintLoad: boolean = false;
    selectedTab = 'itemsNoPrinted';
    csvDataR: any;

    isSortSettings = {
        createdAt: { active: false, sorting: false },
        postalServiceAlias:  { active: false, sorting: false },
        widthPageAtt:  { active: false, sorting: false },
        status:  { active: false, sorting: false }
    };

    constructor(
        private apiService: EpochtaApiService,
        private postHub: PostHubService,
        private csvService: CsvService,
        private titleService: Title
    ) { }

    async ngOnInit() {
        this.allPosts = this.posts = await this.apiService.posts.getOfficeAllPosts();
        this.posts = this.posts.filter(m => !m.isPrinted);
        this.office = await this.apiService.office.getCurrentOffice();
        this.titleService.setTitle('CDEK: ' + this.office.name);
        this.postHub.start(this.office.id);
        this.startSignalRListener();
        this.codes = this.office.postcodes.length > this.countShowPostCodes ?
            this.office.postcodes.slice(0, this.countShowPostCodes).join(", ") + "..." : this.office.postcodes.join(", ");
        this.allCodes = this.office.postcodes.join(", ");
    }

    startSignalRListener() {
        console.log('startSignalRListener');

        this.postHub.postUpdateEvent
            .subscribe((post) => setTimeout(() => {
                console.log(post);
                this.updateOrAddPostSignalR(post);
            }, 1000));
    }

    private tmUpdate;
    async updateOrAddPostSignalR(p: PostInfo) {
        console.log("Добавлено новое отправление");
        console.log(p);
        const post = this.posts.find(el => el.id == p.id)

        if (this.tmUpdate)
            clearTimeout(this.tmUpdate);
        this.tmUpdate = setTimeout(async () => {
            this.allPosts = await this.apiService.posts.getOfficeAllPosts();
            if (this.posts[0]?.isPrinted) {
                this.posts = this.allPosts.filter(p => p.status != 'new' && p.status != 'waitingPayment' && p.isPrinted)
            } else {
                this.posts = this.allPosts.filter(p => p.status != 'new' && p.status != 'waitingPayment' && !p.isPrinted)
            }
        }, 5000);
    }

    printAll() {
        this.isAllPrintLoad = true;

        const ids = [];
        this.posts.forEach(p => {
            if (p.postalServiceAlias == PostalServiceAlias.Cdek && (p.status == 'new' || p.status == 'waitingPayment' || !p.cdekNumber))
                return;

            if (p.postalServiceAlias == PostalServiceAlias.Nationalpost && (p.status == 'new' || p.status == 'waitingPayment' || !p.spi))
                return;

            ids.push(p.id)
        });

        this.apiService.posts.printPosts(ids)
            .subscribe(res => {
                var file = new Blob([res], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                this.isAllPrintLoad = false;
                window.open(fileURL);
            }, () => {
                this.isAllPrintLoad = false;
            });
    }

    async print(postInfo: PostInfo, e) {
        postInfo.isLoad = true;
        e.preventDefault()
        e.stopPropagation();

        this.apiService.posts.printPosts([postInfo.id]).subscribe(res => {
            var file = new Blob([res], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            postInfo.isLoad = false;
            window.open(fileURL);
        });
        await this.apiService.posts.setPrinted(postInfo.id);
        postInfo.isPrinted = true;
    }

    linkDownload(postId: string, attId: string) {
        return this.apiService.posts.getLink(postId, attId)
    }

    isPrinted(status) {
        return status == "new" || status == 'waitingPayment' || status == 'waitingOfficeActions';
    }

    filterPosts(status: string) {
        switch (status) {
            case "ItemsPdf": {
                this.posts = this.allPosts.filter(m => m.attachments?.filter(a => !a.isWaybill).length == m.attachments?.length);
                this.selectedTab = 'itemsPdf';
                return;
            }
            case "ItemsDelivery": {
                this.posts = this.allPosts.filter(m => m.status == 'waitingDelivery');
                return;
            }
            case "ItemsPrinted": {
                this.posts = this.allPosts.filter(m => m.isPrinted);
                this.selectedTab = 'itemsPrinted';
                return;
            }
            case "ItemsNoPrinted": {
                this.posts = this.allPosts.filter(m => !m.isPrinted);
                this.selectedTab = 'itemsNoPrinted';
                return;
            }
            case "ArchivedItems": {
                this.posts = this.allPosts.filter(m => m.status == 'completed');
                return;
            }
        };

        this.posts = this.allPosts
    }

    countPageAtt(post: PostInfo): any {
        let r = 0;
        post.attachments.forEach(a => {
           r = r + a.pageCount;
        });
        return r;
    }

    widthPageAtt(post: PostInfo): any {
        let r = 0;
        post.attachments.forEach(a => {
            r = r + a.pageCount;
        });
        r = r * 5;
        post.widthPageAtt = r;
        return r;
    }

    sortPosts(el: HTMLElement): any {
        const name = el.getAttribute('name');

        this.isSortSettings.createdAt.active = false;
        this.isSortSettings.postalServiceAlias.active = false;
        this.isSortSettings.widthPageAtt.active = false;
        this.isSortSettings.status.active = false;

        switch (el.getAttribute('name')) {
            case 'createdAt': {
                this.isSortSettings.createdAt.active = true;
                if (this.isSortSettings.createdAt.sorting) {
                    this.posts = this.posts.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)) || [];
                    this.isSortSettings.createdAt.sorting = false;
                } else {
                    this.posts = this.posts.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)) || [];
                    this.isSortSettings.createdAt.sorting = true;
                }
                break;
            }
            case 'status': {
                this.isSortSettings.status.active = true;
                if (this.isSortSettings.status.sorting) {
                    this.posts = this.posts.sort((a, b) => (a.status < b.status ? 1 : -1)) || [];
                    this.isSortSettings.status.sorting = false;
                } else {
                    this.posts = this.posts.sort((a, b) => (a.status > b.status ? 1 : -1)) || [];
                    this.isSortSettings.status.sorting = true;
                }
                break;
            }
            case 'postalServiceAlias': {
                this.isSortSettings.postalServiceAlias.active = true;
                if (this.isSortSettings.postalServiceAlias.sorting) {
                    this.posts = this.posts.sort((a, b) => (a.postalServiceAlias < b.postalServiceAlias ? 1 : -1)) || [];
                    this.isSortSettings.postalServiceAlias.sorting = false;
                } else {
                    this.posts = this.posts.sort((a, b) => (a.postalServiceAlias > b.postalServiceAlias ? 1 : -1)) || [];
                    this.isSortSettings.postalServiceAlias.sorting = true;
                }
                break;
            }
            case 'widthPageAtt': {
                this.isSortSettings.widthPageAtt.active = true;
                if (this.isSortSettings.widthPageAtt.sorting) {
                    this.posts = this.posts.sort((a, b) => (a.widthPageAtt < b.widthPageAtt ? 1 : -1)) || [];
                    this.isSortSettings.widthPageAtt.sorting = false;
                } else {
                    this.posts = this.posts.sort((a, b) => (a.widthPageAtt > b.widthPageAtt ? 1 : -1)) || [];
                    this.isSortSettings.widthPageAtt.sorting = true;
                }
                break;
            }
        }
    }

    public export(): any {
        this.apiService.csv.genCsvFile(this.office.id).subscribe(res => {
            const file = new Blob([res], { type: 'text/csv;charset=windows-1251;' });
            const fileURL = URL.createObjectURL(file);
            const dwldLink = document.createElement('a');
            const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
            if (isSafariBrowser) {  // если Safari откроется новое окне, чтобы сохранить файл со случайным именем.
                dwldLink.setAttribute('target', '_blank');
            }
            dwldLink.setAttribute('href', fileURL);
            dwldLink.setAttribute('download', 'Реестр.csv');
            dwldLink.style.visibility = 'hidden';
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
        });
    }

    genCoverPrint(postId, width, height) {
        window.open(this.apiService.pdf.postCoverPrint(postId, width, height), '_blank');
    }
}
