<app-header [title]="'Исходящие'" [officeName]="office?.name"></app-header>
<div class="container content">
    <div class="">
        <div class="info">
            <div class="info__title">Зона обслуживания: <label [title]="allCodes">
                <span *ngFor="let o of office?.postcodes; let i = index;">{{office?.postcodes.length > 1 && i != 0 ? ', '+o.trim() : o.trim()}}</span>
            </label></div>
            <div class="info__stat">
                <div class="info__btn-actions">
                    <button class="btn tap-link" [class]="selectedTab == 'itemsPrinted' ? 'btn-success' : 'btn-light'" [class.btn-selected]="selectedTab == 'itemsPrinted'" (click)="filterPosts('ItemsPrinted')">
                        <span>Распечатанные: </span> {{allPosts | isPrinter}}
                    </button>
                    <button class="btn tap-link" [class]="selectedTab == 'itemsNoPrinted' ? 'btn-success' : 'btn-light'" [class.btn-selected]="selectedTab == 'itemsNoPrinted'" (click)="filterPosts('ItemsNoPrinted')">
                        <span>Не распечатанные: </span> {{allPosts | isPrinter:false}}
                    </button>
                    <button class="btn tap-link" [class]="selectedTab == 'itemsPdf' ? 'btn-success' : 'btn-light'" [class.btn-selected]="selectedTab == 'itemsPdf'" (click)="filterPosts('ItemsPdf')">
                        <span>Без накладной: </span> {{(allPosts | isPdf) || 0}}
                    </button>
                </div>
                <div class="block-right-btn-header-table">
                    <button *ngIf="!isAllPrintLoad" class="btn btn-success btn-export" (click)="export()">
                        <span>Выгрузить реестр</span>
                    </button>
                    <div *ngIf="isAllPrintLoad" class="spinner-border text-success" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <button *ngIf="!isAllPrintLoad" class="btn btn-success" (click)="printAll()">
                        <span>Распечатать все показанные</span>
                    </button>
                </div>
            </div>
            <div class="text-center mt-2" *ngIf="!posts || !posts.length">
                Создайте новое отправление, чтобы оно появилось на этом экране.
            </div>
        </div>

        <ng-container *ngIf="posts && posts.length">
            <div class="block-border-tab">
                <div class="main-table-post main-table-post-header">
                    <div class="post post-tr">
                        <div class="col-3">
                            <span>Получатель</span>
                        </div>
                        <div class="col-2">
                            <span>Отправитель</span>
                        </div>
                        <div class="col-2">
                            <span>Код отслеживания</span>
                        </div>
                        <div class="col-1">
                            <span class="action-sort" (click)="sortPosts($event.target)" name="createdAt" [class.desc]="isSortSettings.createdAt.sorting" [class.active]="isSortSettings.createdAt.active">Дата регистрации</span>
                        </div>
                        <div class="col-2" style="text-align: center;">
                            <span class="action-sort" (click)="sortPosts($event.target)" name="postalServiceAlias" [class.desc]="isSortSettings.postalServiceAlias.sorting" [class.active]="isSortSettings.postalServiceAlias.active">Почтовая служба</span>
                        </div>
                        <div class="col-1" style="text-align: center;">
                            <span class="action-sort" (click)="sortPosts($event.target)" name="widthPageAtt" [class.desc]="isSortSettings.widthPageAtt.sorting" [class.active]="isSortSettings.widthPageAtt.active">Кол-во листов/Вес</span>
                        </div>
                        <div class="col-1" style="text-align: right;">
                            <span class="action-sort" (click)="sortPosts($event.target)" name="status" [class.desc]="isSortSettings.status.sorting" [class.active]="isSortSettings.status.active">Статус</span>
                        </div>
                    </div>
                </div>
                <div *ngFor="let post of posts; let i = index" class="main-table-post">
                    <div class="post post-tr" *ngIf="post.status != 'new' && post.status != 'waitingPayment'">
                        <div class="col-3" [routerLink]="'/departure-details/' + post.id">
                            <p class="header-td">Получатель:</p>
                            <p>{{post.receiver.name |maxLength:28}}</p>
                            <p>{{post.receiver.phone}}</p>
                            <p>{{post.destination.address|maxLength:40}}</p>
                        </div>
                        <div class="col-2" [routerLink]="'/departure-details/' + post.id">
                            <p class="header-td">Отправитель:</p>
                            <p>{{post.sender.name}}</p>
                            <p>{{post.sender.phone}}</p>
                            <p *ngIf="post.sender.address != 'null, null, null'">{{post.sender.address|maxLength:25}}</p>
                        </div>
                        <div class="col-2">
                            <div>
                                <div *ngIf="post.isLoad" class="spinner-border text-success" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <button *ngIf="!post.isLoad" class="btn"
                                        [ngClass]="post.isPrinted ? 'btn-success' : 'btn-light'" (click)="print(post, $event)">
                                    Распечатать
                                </button>
                                <div *ngIf="post.postalServiceAlias == 1" class="dropdown" appDropdown style="margin-top: 5px;">
                                    <button class="btn btn-success dropdown-toggle" type="button" id="dropdownMenuButton{{i}}" data-bs-toggle="dropdown" aria-expanded="false">
                                        Распечатать конверт
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton{{i}}">
                                        <li><a class="dropdown-item" (click)="genCoverPrint(post.id, 229, 114)">С65 – 114х229мм.</a></li>
                                        <li><a class="dropdown-item" (click)="genCoverPrint(post.id, 229, 162)">С5 – 162х229мм.</a></li>
                                        <li><a class="dropdown-item" (click)="genCoverPrint(post.id, 324, 229)">С4 – 229х324мм.</a></li>
                                    </ul>
                                </div>
                            </div>
                            <a class="a-link-trek" [routerLink]="'/shipment-archive/' + post.id">
                                <span *ngIf="post.cdekNumber && post.postalServiceAlias == 0">#{{post.cdekNumber}}</span>
                                <span *ngIf="post.spi && post.postalServiceAlias == 1">#{{post.spi}}</span>
                                <span *ngIf="!post.cdekNumber && !post.spi">#{{post.id}}</span>
                            </a>
                        </div>
                        <div class="col-1" [routerLink]="'/departure-details/' + post.id">
                            <span>{{post.createdAt | toLocalDate}} </span>
                        </div>
                        <div class="col-2" [routerLink]="'/departure-details/' + post.id">
                            <p style="text-align: center;" [innerHTML]="post.postalServiceAlias == 0 ? 'CDEK' : post.postalServiceAlias == 1 ? 'Национальная <br />почтовая служба' : null"></p>
                        </div>
                        <div class="col-1" [routerLink]="'/departure-details/' + post.id">
                            <p>Кол-во листов: {{countPageAtt(post)}}</p>
                            <p>Вес: {{widthPageAtt(post)}}г.</p>
                        </div>
                        <div class="col-1" [routerLink]="'/departure-details/' + post.id">
                            <div class="post__desc status-{{post.status}}">
                                {{post.status | statusPost}}
                            </div>
                        </div>
                        <span *ngIf="post | isWaybill" class="post-state state-{{!!post.pdf}}">*</span>
                        <span *ngIf="post.postalServiceAlias == '0' && !post.cdekNumber" class="post-state state-{{!!post.cdekNumber}}">!</span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="state-info">
        <span class="post-state state-true">*</span> Отправление с накладной
        <span class="post-state state-false">!</span> Есть ошибки в заполнении
    </div>

</div>
