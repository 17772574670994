import { MaxLengthPipe } from './pipes/max-length.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { ShipmentArchiveComponent } from './pages/shipment-archive/shipment-archive.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OutgoingShipmentHistoryComponent } from './pages/outgoing-shipment-history/outgoing-shipment-history.component';
import { ArchiveComponent } from './pages/archive/archive.component';
import { PopularQuestionsComponent } from './pages/popular-questions/popular-questions.component';
import { DepartureDetailsComponent } from './pages/departure-details/departure-details.component';
import { EpochtaApiService } from './services/epochta-api.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StatusPostPipe } from './pipes/status-post.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { ToLocalDatePipe } from './pipes/to-local-date.pipe';
import { PrivacyPolicyComponent } from './pages/policy/privacy-policy/privacy-policy.component';
import { PersonalDataPolicyComponent } from './pages/policy/personal-data-policy/personal-data-policy.component';
import { IsPrinterPipe } from './pipes/is-printer.pipe';
import { IsPdfPipe } from './pipes/is-pdf.pipe';
import { IsWaybillPipe } from './pipes/is-waybill.pipe';
import {CsvService} from "./services/csv.service";
import { DropdownDirective } from './directives/dropdown.directive';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    ShipmentArchiveComponent,
    OutgoingShipmentHistoryComponent,
    ArchiveComponent,
    PopularQuestionsComponent,
    DepartureDetailsComponent,
    StatusPostPipe,
    ToLocalDatePipe,
    MaxLengthPipe,
    PrivacyPolicyComponent,
    PersonalDataPolicyComponent,
    IsPrinterPipe,
    IsPdfPipe,
    IsWaybillPipe,
    DropdownDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
  ],
  providers: [EpochtaApiService, CsvService],
  bootstrap: [AppComponent]
})
export class AppModule { }
