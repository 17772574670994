import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxLength'
})
export class MaxLengthPipe implements PipeTransform {

  transform(text: string, num: number) {
    if (!text) return;

    if (text.length > num){
      return text.substring(0, num) + "...";
    }

    return text;
  }

}
