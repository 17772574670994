import { Injectable } from '@angular/core';

@Injectable()
export class CsvService {
    public downloadFile(data, filename = 'data'): any {
        const csvData = this.convertToCSV(data, ['Индекс', 'Получатель', 'Адрес', 'ШПИ', 'Уин', 'Комментарий', 'Телефон', 'email', 'признак юр лица 1- юр о - физ']);
        const blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=windows-1251;'});
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        if (isSafariBrowser) {  // если Safari откроется новое окне, чтобы сохранить файл со случайным именем.
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', filename + '.csv');
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    private convertToCSV(objArray, headerList): any {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = ';';
        // for (const index in headerList) {
        //     row += headerList[index] + ';';
        // }
        // row = row.slice(0, -1);
        // str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = (i + 1) + '';
            for (const index in headerList) {
                const head = headerList[index];
                line += ';' + '' + array[i][head].replace(/,/gi, '').replace(/"/gi, '').trim() + '';
            }
            str += line + '\r\n';
        }
        return str;
    }
}
