import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusPost'
})
export class StatusPostPipe implements PipeTransform {

  transform(status: string) {
    switch(status){
      case "new" : return "Создано";
      case "waitingPayment" : return "Заполнено";
      case "waitingOfficeActions" : return "Оплачено";
      case "waitingForCourier" : return "Распечатано";
      case "waitingDelivery" : return "В доставке";
      case "completed" : return "Доставлено";
      case "noticeLeft" : return "Оставлено извещение";
      case "notSent" : return "Не доставлено";
    }
    return status;
  }

}
