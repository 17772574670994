import { PostHubService } from './../../services/post-hub.service';
import { LoginReq } from './../../models/login.model';
import { EpochtaApiService } from './../../services/epochta-api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    submitted = false;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private apiService: EpochtaApiService,
    ) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            login: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    get f() {
        return this.loginForm.controls;
    }

    async onSubmit() {
        this.submitted = true;

        let login = this.loginForm.controls['login'].value;
        let password = this.loginForm.controls['password'].value;

        if (this.loginForm.invalid) {
            return;
        }

        const loginReq: LoginReq = {
            login: this.loginForm.controls['login'].value,
            pwd: this.loginForm.controls['password'].value
        }

        const res = await this.apiService.office.login(loginReq);
        if (res) {
            localStorage.setItem("auth", res);
            this.router.navigateByUrl('/history');
        }
    }
}
