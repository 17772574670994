<app-header [title]="'Исходящие'"></app-header>
<div class="container content">
    <div class="row">
        <div class="col-lg-8 col-12">
            <div class="text-center mt-2" *ngIf="!posts || !posts.length">
                Доставленных отправлений нет. Отправления появляются здесь после успешной доставки.
            </div>
            <div class="messages" *ngFor="let post of posts">
                <div class="message">
                    <div class="message-left message__left">
                        <h3 class="message__name mb-1"><a [routerLink]="'/departure-details/' + post.id">Заказное
                                письмо:
                                {{post.receiver.name}}</a></h3>
                        <div class="message__address">{{post.destination.address}}</div>
                        <div class="d-flex align-items-center">
                            <a routerLink="/shipment-archive" class="btn btn-link message__link mr-2">Отслеживание
                                доставки</a>
                            <a *ngIf="isQuintation(post.status)" (click)="genPdf(post.id)" target="_blank"
                                class="btn btn-link message__link">Квинтация о вручении</a>
                        </div>
                    </div>
                    <div class="message-right message__right">
                        <div class="d-flex">
                            <div class="message__date mb-3">{{post.createdAt | toLocalDate}}</div>
                        </div>
                        <div class="message__id mb-3">
                            #{{post.id}}
                        </div>
                        <div class="message__status">
                            {{post.status | statusPost}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
